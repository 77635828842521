<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient00" x1="1" x2="0.193" y2="0.815">
     <stop offset="0" stop-color="#fb2"/>
     <stop offset="1" stop-color="#be28cb"/>
    </linearGradient>
   </defs>
   <g id="Instagram" transform="translate(-210 -208)">
    <rect :fill="background" data-name="Rectangle 74" height="60" id="Rectangle_74" transform="translate(210 208)" width="60"/>
    <path d="M15,9.544a7.69,7.69,0,1,0,7.69,7.69A7.678,7.678,0,0,0,15,9.544Zm0,12.689a5,5,0,1,1,5-5,5.009,5.009,0,0,1-5,5Zm9.8-13A1.794,1.794,0,1,1,23,7.436,1.789,1.789,0,0,1,24.8,9.23Zm5.093,1.82a8.876,8.876,0,0,0-2.423-6.284,8.935,8.935,0,0,0-6.284-2.423c-2.476-.141-9.9-.141-12.375,0A8.922,8.922,0,0,0,2.523,4.759,8.9,8.9,0,0,0,.1,11.044c-.141,2.476-.141,9.9,0,12.375A8.876,8.876,0,0,0,2.523,29.7a8.946,8.946,0,0,0,6.284,2.423c2.476.141,9.9.141,12.375,0A8.876,8.876,0,0,0,27.467,29.7a8.935,8.935,0,0,0,2.423-6.284c.141-2.476.141-9.892,0-12.368Zm-3.2,15.025a5.062,5.062,0,0,1-2.851,2.851c-1.974.783-6.659.6-8.841.6s-6.873.174-8.841-.6a5.062,5.062,0,0,1-2.851-2.851c-.783-1.974-.6-6.659-.6-8.841s-.174-6.873.6-8.841A5.062,5.062,0,0,1,6.157,5.542c1.974-.783,6.659-.6,8.841-.6s6.873-.174,8.841.6A5.062,5.062,0,0,1,26.69,8.393c.783,1.974.6,6.659.6,8.841S27.473,24.108,26.69,26.075Z" data-name="Icon awesome-instagram" id="Icon_awesome-instagram" style="fill: url(#linear-gradient00);" transform="translate(225.005 220.762)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>